<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('webhookHistoryTitle') }}</h3>
  </div>
  <paginated-table-wrapper v-if="license" :sort="'desc'" :license="license" :request="getWebhookHistoryRequest"
    :csv-handler="downloadCsvHandler" :is-search="false" v-slot="{ list, isLoading }">
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table :list="getActualListHandler(list)" @retry="retryHandler"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { getWebhookHistory, postWebhookHistoryRetryApi } from '../../services/statistic.js'
import { store } from '@/application/store'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formattedCSVWebhookHistory } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'WebhookHistoryPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/WebhookHistoryTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const updatedItems = ref([])
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getWebhookHistoryRequest = getWebhookHistory
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'webhook-history.csv',
      formattedCSVWebhookHistory,
      {
        webhook_id: i18n.t('webhook_id'),
        message_id: i18n.t('message_id'),
        status: i18n.t('status'),
        attempt: i18n.t('attempt'),
        max_attempts: i18n.t('max_attempts'),
        event_type: i18n.t('eventType'),
        last_update: i18n.t('lastUpdate'),
        date: i18n.t('date'),
      }
    )

    const getActualListHandler = (list) => {
      if (updatedItems.value.length === 0) {
        return list
      }

      const data = updatedItems.value.pop()
      const index = list.findIndex(item => item.webhook_id === data.webhook_id)
      list.splice(index, 1, data)
      return list
    }

    const retryHandler = (id) => postWebhookHistoryRetryApi(id, { Authorization: license.value.license_key })
      .then(({ data }) => {
        updatedItems.value.push(data)
      })
      .catch(({ err }) => {
        console.log(err)
      })

    return {
      license,
      getWebhookHistoryRequest,
      downloadCsvHandler,
      retryHandler,
      getActualListHandler,
    }
  },
}
</script>
